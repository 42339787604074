* {
  box-sizing: border-box;
}

body {
  background-color: #121212;
  color: #fff;
}

html,
body {
  width: 99%;
}

.MuiDrawer-paper * {
  color: #fff;
}

.voteContainer {
  width: 100%;
  background-color: #ddd;
}

.voteBar {
  text-align: right;
  padding-top: 10px;
  padding-bottom: 10px;
  color: white;
}

.MuiCircularProgress-root {
  display: block !important;
  margin: 15px auto;
}

ul.MuiList-root.MuiList-padding a {
  color: #222 !important;
  text-decoration: none;
}

span.flag-icon {
  border: 1px solid #ccc;
}
.MuiContainer-root {
  width: 95% !important;
  padding: 0 !important;
  margin: 0 auto;
}
